import type { FC } from 'react'
import type { InstrumentEditorProps } from '../instrument-editor.props.js'

const BassEditor: FC<InstrumentEditorProps> = props => {
  const {} = props

  return <div>Empty</div>
}

export default BassEditor
