import { renderContext, useEditor, usePlugins } from '@tunasong/plugin-lib'
import { useMusicContext } from '@tunasong/redux'
import type { SidebarComponent } from '@tunasong/ui-lib'
import type { FC } from 'react'
import Panel from '../views/panel.js'

export const SidebarContext: FC<SidebarComponent> = () => {
  const editor = useEditor({ allowNull: true })
  const [context] = useMusicContext({ editorId: editor?.id, selector: state => state })
  const plugins = usePlugins('all')
  const contextContent = context && plugins && editor ? renderContext(editor, context, Panel, plugins) : null
  return contextContent
}
