import { ToggleButton } from '@mui/material'
import { ViewSidebar as ViewSidebarIcon } from '@tunasong/icons'
import { useLayout, useLayoutsExtended } from '@tunasong/plugin-lib'
import type { BaseLayoutSpec } from '@tunasong/plugin-lib'
import {
  LayoutSelector,
  Presence,
  TunaEntityBreadcrumbs,
  useDimensions,
  useNavigateToEntity,
  useSidebar,
} from '@tunasong/ui-lib'
import type { MouseEvent } from 'react'
import CreateEntityButton from '../entity/create-entity-button.js'
import { Header } from '../header.js'
import type { ChromeProps } from './chrome.js'
import { EntityMenuButton } from './entity-menu.js'

export interface EntityHeaderProps extends ChromeProps {
  ref?: React.Ref<HTMLDivElement>
}

export const EntityHeader = (props: EntityHeaderProps) => {
  const { entity, layoutName, showSidebar, ref } = props

  const { isTiny } = useDimensions()

  const layouts = useLayoutsExtended(entity)
  const navigateToEntity = useNavigateToEntity({ alwaysUseDefaultRouter: true })
  const navigateToLayout = (layout: BaseLayoutSpec) => navigateToEntity(entity, { layout: layout.name })

  const layout = useLayout({ entity, layoutName })

  const entityId = entity?.id

  const { setActiveTool, open: sidebarOpen, setOpen: setSidebarOpen } = useSidebar()

  const handleToggleSidebar = (ev: MouseEvent) => {
    ev.preventDefault()
    if (!sidebarOpen) {
      setSidebarOpen(true)
      setActiveTool('Settings')
    } else {
      setSidebarOpen(false)
      setActiveTool(null)
    }
  }

  const container = layout?.container ? layout.container : {}

  return (
    <Header
      ref={ref}
      position="absolute"
      elevation={0}
      controls={[
        <CreateEntityButton key={0} parent={entity} />,
        layouts.length > 0 ? (
          <LayoutSelector selected={layout ?? undefined} layouts={layouts} key={211} onChange={navigateToLayout} />
        ) : null,

        <Presence key={10} entityId={entityId} sx={{ mx: 1 }} />,
        showSidebar && isTiny ? (
          <ToggleButton
            size="small"
            value="sidebar"
            selected={sidebarOpen}
            key={11}
            onClick={handleToggleSidebar}
            aria-label="Toggle sidebar"
          >
            <ViewSidebarIcon color={sidebarOpen ? 'secondary' : undefined} />
          </ToggleButton>
        ) : null,
        <EntityMenuButton key={12} entity={entity} />,
      ].filter(Boolean)}
      showBackButton={container?.showBreadcrumbs !== false}
      breadcrumbs={
        container?.showBreadcrumbs !== false ? (
          <TunaEntityBreadcrumbs sx={{ flex: 1 }} entity={entity} showHome={false} />
        ) : undefined
      }
    />
  )
}
