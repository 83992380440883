import { Button, Menu } from '@mui/material'
import { MoreVertIcon } from '@tunasong/icons'
import { RoomMenu, RoomOverlay } from '@tunasong/plugin-video/ui'
import { usePresence } from '@tunasong/ui-lib'
import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { useEntityMenuItems } from './entity-menu.hook.js'

import type { Entity, Persisted } from '@tunasong/schemas'
import HelpMenuItem from '../help/help-menu.js'

export interface EntityMenuButtonProps {
  className?: string
  entity?: Persisted<Entity>
  showFilter?: boolean
}

export const EntityMenuButton: FC<EntityMenuButtonProps> = props => {
  const { className, entity } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleOpen = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(ev.currentTarget), [])
  const handleClose = useCallback(() => setAnchorEl(null), [])
  /** @todo should we use presence here? */
  const present = usePresence(entity?.id)

  const { menuItems: entityMenuItems, dialogs: entityDialogs } = useEntityMenuItems({
    entity,
    onClose: handleClose,
    onCloseMenu: handleClose,
  })

  /** Room overlay */
  const [showRoomOverlay, setShowRoomOverlay] = useState(false)
  const toggleRoom = useCallback(() => {
    handleClose()
    setShowRoomOverlay(o => !o)
  }, [handleClose])

  return (
    <>
      <Button color="inherit" onClick={handleOpen} aria-label="Menu Dropdown Button">
        <MoreVertIcon />
      </Button>
      <Menu
        className={className}
        slotProps={{
          list: {
            sx: { minWidth: 200 },
          },
        }}
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {...entityMenuItems}

        <RoomMenu open={showRoomOverlay} entityId={entity?.id} onClick={toggleRoom} disabled={present.length === 0} />

        <HelpMenuItem onClose={handleClose} />
      </Menu>

      {showRoomOverlay ? <RoomOverlay open={showRoomOverlay} entityId={entity?.id} /> : null}
      {...entityDialogs}
    </>
  )
}
