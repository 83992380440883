import { Box, Typography } from '@mui/material'

import React from 'react'
import { DeadFish } from '@tunasong/icons'
import { AlertError } from '@tunasong/ui-lib'

export interface ErrorProps {
  error?: unknown
}

export const ErrorGeneral = ({ error }: ErrorProps) => (
  <Box
    sx={{
      margin: theme => theme.spacing(4),
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'auto',
    }}
  >
    <DeadFish
      style={{
        // color: theme.vars.palette.error.main,
        width: 300,
        height: 300,
      }}
    />
    <Typography variant="h3" sx={{ mb: 8 }}>
      A dead Tuna is a sad Tuna.
    </Typography>

    <AlertError error={error} />
  </Box>
)
