import { useTheme } from '@mui/material'
import { styled, useColorScheme } from '@mui/material/styles'
import { ScaleLib, circleOfFifths as createCircleOfFifths } from '@tunasong/music-lib'

import React, { useCallback, useEffect } from 'react'
import type { FC } from 'react'

import { logger } from '@tunasong/models'
import { drawCircleOfFifths } from './draw-circle.js'
import type { Scale } from '@tunasong/schemas'

export interface CircleOfFifthsProps {
  className?: string
  scale?: Scale
  transpose?: number
}

const StyledCanvas = styled('canvas')({
  display: 'block',
  width: '100%',
  margin: 0,
  padding: 0,
})

export const CircleOfFifths: FC<CircleOfFifthsProps> = props => {
  const { className, transpose = 0, scale = { root: 'C', type: 'major' } as Scale } = props
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const theme = useTheme()
  const { colorScheme = 'light' } = useColorScheme()

  const draw = useCallback(
    (canvas: HTMLCanvasElement) => {
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        return
      }
      const PIXEL_RATIO = window.devicePixelRatio
      canvas.width = canvas.offsetWidth * PIXEL_RATIO
      /** height === width */
      canvas.height = canvas.width
      ctx.setTransform(PIXEL_RATIO, 0, 0, PIXEL_RATIO, 0, 0)

      logger.debug(`Redraw to ${canvas.width}:${canvas.height}, offset: ${canvas.offsetHeight}:${canvas.offsetWidth}`)
      const transposedScale = ScaleLib.transpose(scale, transpose)
      const circleOfFifths = createCircleOfFifths(
        scale.type === 'major' ? transposedScale.root : ScaleLib.getRelative(transposedScale).root
      )
      drawCircleOfFifths({
        ctx,
        theme,
        width: canvas.offsetWidth,
        height: canvas.offsetHeight,
        circleOfFifths,
        colorScheme,
      })
    },
    [colorScheme, scale, theme, transpose]
  )

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) {
      return
    }

    draw(canvas)

    /** Redraw on resize */
    const redraw = () => draw(canvas)
    window.addEventListener('resize', redraw)

    /** Unsubscribe on unmount */
    return () => {
      window.removeEventListener('resize', redraw)
    }
  }, [draw, scale])

  if (!(scale.root && scale.type)) {
    return null
  }

  return <StyledCanvas className={className} ref={canvasRef} width="300" height="300" />
}

export default CircleOfFifths
