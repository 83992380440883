import type { ListItemProps, TypographyProps } from '@mui/material'
import { Box, DialogContent, Divider, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import { Piano } from '@tunasong/icons'
import type { Chord } from '@tunasong/music-lib'
import type { Scale } from '@tunasong/schemas'
import { Dialog } from '@tunasong/ui-lib'
import type { MouseEvent } from 'react'
import { useState } from 'react'
import { SimpleChord } from '../chord/simple-chord.js'
import { ScaleSelect } from '../scale/scale-select.js'
import { KeyboardChord } from './keyboard-chord.js'

interface KeyboardMenuItemProps extends Omit<ListItemProps, 'button'> {
  onClick?: () => void
  variant?: TypographyProps['variant']
}

export const useKeyboardMenuItem = (props: KeyboardMenuItemProps = {}) => {
  const { onClick, variant = 'body1', ...restProps } = props

  const [scale, setScale] = useState<Scale | null>({ root: 'C', type: 'major' })
  const [chords, setChords] = useState<Chord[]>([])

  const [show, setShow] = useState(false)

  const handleShow = (ev: MouseEvent) => {
    ev.preventDefault()
    setShow(true)
    if (onClick) {
      onClick()
    }
  }

  const handleHide = () => {
    setShow(false)
  }

  return {
    dialog: (
      <Dialog open={show} onClose={handleHide} title="Chord Finder" fullWidth={true} maxWidth="md">
        <DialogContent>
          <ScaleSelect value={scale} onChange={setScale} />
          <Divider sx={{ my: 2 }} />
          <KeyboardChord scale={scale} showLabelOnActiveOnly={true} largeKeyboard={true} onChordChange={setChords} />

          <Divider sx={{ my: 2 }}>
            <Typography variant="caption">Identified Chords</Typography>
          </Divider>
          <Box sx={{ minHeight: 50 }}>
            {chords.map((c, idx) => (
              <SimpleChord sx={{ fontSize: '2em' }} key={idx} chord={c} />
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    ),
    menuItem: (
      <MenuItem title="Tuner" color="inherit" onClick={handleShow} {...restProps}>
        <ListItemIcon>
          <Piano />
        </ListItemIcon>
        <ListItemText>
          <Typography variant={variant}>Chord Finder</Typography>
        </ListItemText>
      </MenuItem>
    ),
  }
}
