import { TextField } from '@mui/material'
import type { Instrument } from '@tunasong/schemas'
import type { FC } from 'react'
import { useCallback } from 'react'
// import { EffectList } from '../effects'
import { Editors } from './editors/index.js'
import type { InstrumentEditorProps } from './instrument-editor.props.js'

const InstrumentEditor: FC<InstrumentEditorProps> = props => {
  const { instrument, onChange } = props

  const handleChange = useCallback(
    (i: Instrument) => {
      if (onChange) {
        onChange(i)
      }
    },
    [onChange]
  )

  const handleFieldChange = useCallback(
    (field: keyof Instrument) => (value: unknown) => {
      handleChange({ ...instrument, [field]: value })
    },
    [handleChange, instrument]
  )

  const handleInput = useCallback(
    (field: keyof Instrument) =>
      ({ target: { value } }: { target: { value: string } }) =>
        handleFieldChange(field)(value),
    [handleFieldChange]
  )

  const { type /* effects = []*/ } = instrument

  const DetailsEditor = Editors[type]

  return (
    <div>
      <TextField label="Name" fullWidth={true} defaultValue={instrument?.name} onBlur={handleInput('name')} />

      {DetailsEditor ? <DetailsEditor instrument={instrument} onChange={handleChange} /> : null}

      {/* <EffectList effects={effects} onChange={handleFieldChange('effects')} /> */}

      <TextField
        label="Notes"
        fullWidth={true}
        multiline={true}
        defaultValue={instrument?.notes}
        onBlur={handleInput('notes')}
      />
    </div>
  )
}

export default InstrumentEditor
