import { Grid, MenuItem, MenuList } from '@mui/material'
import { useEditorClass } from '@tunasong/editor'
import { useState } from 'react'
import type { FC } from 'react'
import { useReleaseNotes } from './release-notes.hook.js'
import { graphHooks } from '@tunasong/graph-lib/react'

export interface ReleaseNotesProps {}

export const ReleaseNotesList: FC<ReleaseNotesProps> = () => {
  const { releaseNotes } = useReleaseNotes()
  const PageEditor = useEditorClass('PageEditor')

  const [selectedVersion, setSelectedVersion] = useState<string>(releaseNotes[0].version)

  const currentReleaseNote = releaseNotes.find(r => r.version === selectedVersion) ?? releaseNotes[0]

  const { entity } = graphHooks.useEntity(currentReleaseNote?.entityId)

  if (!entity) {
    return null
  }

  return (
    <Grid container>
      <Grid size={{ xs: 3 }}>
        <MenuList>
          {releaseNotes.map(r => (
            <MenuItem
              key={r.version}
              value={r.version}
              selected={selectedVersion === r.version}
              onClick={() => setSelectedVersion(r.version)}
            >
              {r.version}
            </MenuItem>
          ))}
        </MenuList>
      </Grid>
      <Grid size={{ xs: 9 }}>
        <PageEditor element={entity} readOnly={true} />
      </Grid>
    </Grid>
  )
}
