/** Wraps a search bar and result list */

import AddLink from '@mui/icons-material/AddLink'
import { Button } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { EntityFilters, isEditablePage, toEntityLink } from '@tunasong/models'
import type { SearchSummary } from '@tunasong/models'
import type { BlockCommand } from '@tunasong/plugin-block/ui'
import { runEditorCommand, useEditor } from '@tunasong/plugin-lib'
import type { Entity, Persisted, SearchFilter } from '@tunasong/schemas'
import { useCallback, useMemo, useState } from 'react'
import type { MouseEvent } from 'react'
import { allQueries } from './queries/index.js'
import { SearchBar } from './search-bar.js'
import { useAdvancedSearch } from './search.hook.js'
import { useSimilarSearch } from './similar-search.js'
import { useUserConfig } from '@tunasong/ui-lib'
import { parseQuerySegments } from './lib/parse-segments.js'

export interface FullSearchProps extends Omit<BoxProps, 'onChange'> {
  initialQuery?: string
  currentEntity?: Persisted<Entity>
  selected?: SearchSummary
  size?: number
  defaultFilter?: SearchFilter
  showRecentOnEmptyQuery?: boolean
  onOpen(result: SearchSummary): void
  onClose(): void
}
export const FullSearch = ({
  onOpen,
  currentEntity,
  initialQuery = '',
  defaultFilter,
  showRecentOnEmptyQuery = false,
  selected,
  size = 25,
  ...boxProps
}: FullSearchProps) => {
  const [query, setQuery] = useState<string>(initialQuery)

  const [filter, setFilter] = useState<SearchFilter | undefined>(defaultFilter)
  /** The query without the filter.  */
  const [cleanQuery, setCleanQuery] = useState<string | undefined>(undefined)
  const handleQuery = (query: string) => {
    setQuery(query)
    const { filter, cleanQuery } = parseQuerySegments(query)
    setCleanQuery(cleanQuery)
    setFilter(filter)
  }

  const [userConfig] = useUserConfig()

  const queryString = cleanQuery ?? query

  const querySpec =
    queryString || filter
      ? allQueries.default({ query: queryString, size, filter })
      : showRecentOnEmptyQuery
        ? allQueries.recent({ size })
        : undefined

  const { results, searching = false } = useAdvancedSearch(querySpec, {
    resultFilter: EntityFilters.defaultSearch,
    size,
    debounceDelay: 200,
    queryType: 'default',
  })

  const { results: similar } = useSimilarSearch(
    { entityId: currentEntity?.id },
    { size: 3, queryType: 'relatedContent' }
  )

  const editor = useEditor({ allowNull: true })

  const handleOpen = useCallback(
    (result: SearchSummary) => {
      onOpen(result)
    },
    [onOpen]
  )

  const isEditable = useMemo(() => isEditablePage(currentEntity), [currentEntity])

  const plugins = editor?.pluginList
  const addLink = useCallback(
    (entity: Persisted<Entity>) => (ev: MouseEvent) => {
      const { id } = entity
      if (!(editor && id && plugins)) {
        return
      }
      ev.stopPropagation()
      const entityLink = toEntityLink(entity)
      editor.insertNode(entityLink)

      runEditorCommand<BlockCommand>({
        commandId: 'block-insert-break',
        editor,
        plugins,
        globalContext: {} as never,
        userConfig,
      })
    },
    [editor, plugins, userConfig]
  )

  const renderActions = useCallback(
    (entity: Persisted<Entity>) => {
      if (!(isEditable && entity)) {
        return null
      }
      return (
        <Button onClick={addLink(entity)} title="Add result">
          <AddLink />
        </Button>
      )
    },
    [addLink, isEditable]
  )

  return (
    <SearchBar
      loading={searching}
      query={query}
      selected={selected}
      entities={results}
      similar={similar}
      fullWidth={true}
      filter={filter}
      onQuery={handleQuery}
      onChange={handleOpen}
      renderHotkeys={renderActions}
      {...boxProps}
    />
  )
}
