import { graphHooks } from '@tunasong/graph-lib/react'
import type { Activity, FeedItem } from '@tunasong/models'
import { dayjs } from '@tunasong/models'
import type { Persisted } from '@tunasong/schemas'
import type { FeedProps } from '@tunasong/ui-lib'
import { Feed } from '@tunasong/ui-lib'
import type { FC, JSX } from 'react'
import { summarizeEntityActivities } from './summarize-activities.js'

export interface ActivityStreamProps extends Omit<FeedProps, 'items'> {
  className?: string
  activities: Persisted<Activity>[]
}

export const ActivityStream: FC<ActivityStreamProps> = props => {
  const { className, activities = [], ...restProps } = props

  const getEntity = graphHooks.useGetEntity()

  const userIds = activities.map(i => i.userId)
  const { getName } = graphHooks.useProfiles({ userIds })

  const getEntityName = (id: string) => getEntity(id)?.name ?? 'Unknown'

  const formatContent = (activity: Persisted<Activity>): string | JSX.Element => {
    if (activity.type === 'create-entity') {
      return `Created a new entity ${getEntityName(activity.entityId)}`
    }
    if (activity.type === 'change-entity') {
      return `Made some edits`
    }
    if (activity.type === 'share') {
      return `Shared with ${activity.principals.map(p => getName(p)).join(', ')}`
    }
    if (activity.type === 'mention') {
      return `Mentioned @${activity.name ?? activity.email}`
    }
    if (activity.type === 'summary') {
      return `Made ${activity.count} changes between ${dayjs(activity.createdAt).format('LL')} and ${dayjs(
        activity.updatedAt
      ).format('LL')}`
    }
    return `Unknown activity`
  }

  const singleEntity = activities.filter(Boolean).every(a => a.entityId === activities[0]?.entityId)

  const items: FeedItem[] = (singleEntity ? summarizeEntityActivities(activities) : activities).map(item => ({
    id: item.id,
    userId: item.lastEditedBy ?? item.userId,
    content: formatContent(item),
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
  }))

  return <Feed className={className} items={items} {...restProps} />
}

export default ActivityStream
