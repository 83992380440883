import { useMic } from '@tunasong/audio-ui'
import { graphHooks } from '@tunasong/graph-lib/react'
import { logger } from '@tunasong/models'
import { useSelector } from '@tunasong/redux'
import { Draggable, withSuspense } from '@tunasong/ui-lib'
import type { FC } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useStream } from '../hooks/index.js'
import { useVideoSignaling } from '../ws/index.js'
import { VideoConnection } from './lib/connection.js'
import { ControlsOverlay } from './room.styles.js'

const Stream = withSuspense(React.lazy(async () => import('./stream.js')))

export interface RoomOverlayProps {
  className?: string
  entityId?: string
  open: boolean
}

/** Video overlay on an entity - can be a room or any other entity */
export const RoomOverlay: FC<RoomOverlayProps> = props => {
  const { entityId, open } = props
  const { entity } = graphHooks.useEntity(entityId)
  const allConnections = useSelector(state => state.webrtc.connections as Record<string, VideoConnection>)
  const connections = Object.values(allConnections).filter(Boolean)
  const started = useRef(false)
  const clientInfo = useSelector(state => state.presence.activeClients)
  const { profiles } = graphHooks.useProfiles()

  const [stream, setStream] = useState<MediaStream | null>(null)

  const getProfileFromPeerId = (peerId: string) => {
    const client = clientInfo[peerId]
    return client?.userId ? profiles?.find(p => p.userId === client.userId) : undefined
  }

  const micControl = useMic()
  const streamControl = useStream()
  /** Start the webcam and mic before starting the peer connection */
  const { startSession, stopSession } = useVideoSignaling({ entity, stream })

  const starting = useRef(false)
  const handleStart = useCallback(async () => {
    if (started.current || starting.current) {
      return
    }
    /** Need to set this here to avoid a race condition for the async below */
    starting.current = true

    /** Start the webcam and mic before starting the peer connection */
    const { shareStream } = await streamControl.start('webcam')
    if (!shareStream) {
      starting.current = false
      return logger.warn(`shareStream is null`)
    }

    await micControl.start()
    setStream(shareStream)
    startSession()

    starting.current = false
    started.current = true
  }, [micControl, startSession, streamControl])

  const handleStop = useCallback(() => {
    if (!started.current) {
      return
    }
    setStream(null)
    stopSession()
    streamControl.stop()
    micControl.stop()
    started.current = false
  }, [micControl, stopSession, streamControl])

  useEffect(() => {
    if (!open || started.current) {
      return
    }

    handleStart().catch(err => logger.error('Failed to start', err))
    return () => {
      handleStop()
    }
  }, [handleStart, handleStop, open])

  if (!open) {
    return null
  }

  return (
    <ControlsOverlay>
      <Draggable>
        <Stream description={'Connected'} srcObject={streamControl.localStream} muted={true} />
      </Draggable>
      {connections.map(c => (
        <Draggable key={c.clientId}>
          <Stream peer={getProfileFromPeerId(c.peerClientId)} description="Connected" srcObject={c.stream} />
        </Draggable>
      ))}
    </ControlsOverlay>
  )
}

export default RoomOverlay
