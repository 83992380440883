import { Box, Typography } from '@mui/material'
import { dayjs } from '@tunasong/models'
import type { MaterialColor } from '@tunasong/plugin-lib'
import { HBox, VBox } from '@tunasong/ui-lib'
import React from 'react'
import type { FC, Ref } from 'react'

export interface ChatItemProps {
  name: string
  timestamp: string
  UserIcon: FC

  color?: MaterialColor

  children: React.ReactNode
  actions?: React.ReactNode

  highlight?: boolean
  type: 'message' | 'response' | 'assistant'

  ref: Ref<unknown>
}

export const ChatItem: FC<ChatItemProps> = props => {
  const { name, actions, UserIcon, type, highlight, timestamp, children, ref } = props

  const iconBox = (
    <Box>
      <UserIcon />
    </Box>
  )
  const contentBox = (
    <Box
      sx={{
        px: 2,
        py: 2,
        margin: 1,
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: theme => theme.spacing(1),
        borderColor: theme => theme.vars.palette.action.hover,
        backgroundColor: theme => theme.vars.palette.action.hover,
        width: '100%',
      }}
    >
      {children}
    </Box>
  )

  const layout =
    type === 'message' ? (
      <>
        {iconBox}
        {contentBox}
        {actions}
      </>
    ) : (
      <>
        {actions}
        {contentBox}
        {iconBox}
      </>
    )
  const position = type === 'message' ? 'left' : 'right'
  const flexPosition = type === 'message' ? 'flex-start' : 'flex-end'
  const textSx = { ml: position === 'left' ? 6 : 0, mr: position === 'right' ? 6 : 0 }

  return (
    <VBox sx={{ alignItems: flexPosition }} ref={ref}>
      <Typography
        sx={{ mt: 2, ...textSx, fontSize: 12, color: theme => theme.vars.palette.text.secondary }}
        variant="body2"
      >
        {name}
      </Typography>
      <HBox
        sx={{
          width: '90%',
          alignItems: 'center',
          justifyContent: flexPosition,
          ...(highlight
            ? {
                borderColor: 'success.light',
                borderStyle: 'dashed',
                borderWidth: 2,
              }
            : {}),
        }}
      >
        {layout}
      </HBox>
      <Box sx={{ ...textSx }}>
        <Typography color="primary.dark" variant="caption">
          {dayjs(timestamp).fromNow()}
        </Typography>
      </Box>
    </VBox>
  )
}

export default ChatItem
