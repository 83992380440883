import { Alert, Grid, capitalize } from '@mui/material'
import type { EntityType } from '@tunasong/schemas'
import { EntityTypeList, useDimensions, useProfileCommands } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useState } from 'react'
import { EventCommandsList } from '../commands/event-commands-list.js'

export interface EventConfigProps {}

export const EventConfig: FC<EventConfigProps> = props => {
  const {} = props
  const [selectedType, setSelectedType] = useState<EntityType>()

  const { eventCommands, onChange } = useProfileCommands(selectedType)
  const { isTiny } = useDimensions()
  const listSize = isTiny ? 'small' : 'medium'

  return (
    <Grid container spacing={2} sx={{ flex: 1, height: '100%', overflow: 'hidden' }}>
      <Grid size={{ xs: 4 }} sx={{ overflow: 'auto', height: '100%' }}>
        <EntityTypeList size={listSize} selected={selectedType} onSelect={val => setSelectedType(val)} />
      </Grid>
      <Grid size={{ xs: 8 }} sx={{ overflow: 'auto', height: '100%' }}>
        {!selectedType ? <Alert severity="info">Select an entity type to configure event handlers.</Alert> : null}
        {selectedType ? (
          <>
            <Alert severity="info">
              Event handlers will run for every {capitalize(selectedType)} entity. These handlers will run only for you.
              Use them to personalize your experience.
            </Alert>

            {selectedType ? <EventCommandsList eventCommands={eventCommands} onChange={onChange} /> : null}
          </>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default EventConfig
