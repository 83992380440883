import type { SelectChangeEvent } from '@mui/material'
import {
  Box,
  capitalize,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useColorScheme,
} from '@mui/material'
import { AutoForm } from '@tunasong/autoform'
import { userConfigSchema, type UserConfig } from '@tunasong/manifest'
import { logger } from '@tunasong/models'
import { Transpose } from '@tunasong/music-ui'
import type { TunaPlugin } from '@tunasong/plugin-lib'
import type { Maturity, Persisted, Profile } from '@tunasong/schemas'
import { useUserConfig } from '@tunasong/ui-lib'
import type { FC } from 'react'
import React, { Fragment, useCallback } from 'react'
import type { ZodSchema } from 'zod'

export interface PluginConfigProps {
  profile: Persisted<Profile>
  plugins: TunaPlugin[]
}

export const PluginConfig: FC<PluginConfigProps> = props => {
  const { plugins } = props

  const [userConfig, updateUserConfig] = useUserConfig()
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  // const { theme = prefersDarkMode ? 'dark' : 'light' } = userConfig

  const handleUpdatePluginConfig = (pluginId: string, schema: ZodSchema, val: Record<string, unknown>) => {
    const data = schema.parse(val)
    updateUserConfig('plugins', {
      ...userConfig.plugins,
      [pluginId]: data,
    })
  }

  const [config, updateConfig] = useUserConfig()

  const handleMaturity = useCallback(
    async (ev: SelectChangeEvent) => updateConfig('featureMaturity', ev.target.value as Maturity),
    [updateConfig]
  )

  const handleTranspose = useCallback(
    async (ev: unknown, transpose: number) =>
      updateConfig('musicPreferences', { ...config.musicPreferences, transpose }),
    [config.musicPreferences, updateConfig]
  )

  /** Iterate all the plugins and check if there are configuration components */

  const configSpecs = plugins
    .map(plugin => ({
      id: plugin.key,
      name: plugin.description,
      // type instantiation workaround
      schema: plugin.config as unknown as ZodSchema<Record<string, unknown>>,
      config: userConfig.plugins[plugin.key] as Record<string, unknown>,
    }))
    .filter(p => p.schema)

  const { mode, setMode } = useColorScheme()

  const handleTheme = useCallback(
    async (ev: React.ChangeEvent<HTMLInputElement>) => {
      const mode = ev.target.value as 'light' | 'dark' | 'system'
      logger.debug('Setting theme to', { mode })
      setMode(mode)
    },
    [setMode]
  )

  type AutoformConfig = Pick<UserConfig, 'showContentColumns'>
  const autoformConfigSchema = userConfigSchema.pick({ showContentColumns: true })
  const handleUpdateConfig = (config: AutoformConfig) => {
    updateConfig(config)
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ my: 2 }}>
        Features
      </Typography>
      <Typography sx={{ pb: theme => theme.spacing(2) }} variant="caption" display={'block'}>
        Specify the maturity of features you want to use.
      </Typography>
      <Select value={config.featureMaturity} onChange={handleMaturity} variant="standard" fullWidth>
        <MenuItem value={'stable'}>Stable</MenuItem>
        <MenuItem value={'beta'}>Beta</MenuItem>
        <MenuItem value={'labs'}>Labs (experimental)</MenuItem>
      </Select>
      <Typography variant="h6" sx={{ my: 2 }}>
        Theme
      </Typography>

      <FormLabel component="legend">UI Theme for this device</FormLabel>
      <RadioGroup sx={{ pl: 2 }} aria-label="theme" name="theme" value={mode} onChange={handleTheme}>
        <FormControlLabel value="system" control={<Radio />} label="System" />
        <FormControlLabel value="light" control={<Radio />} label="Light" />
        <FormControlLabel value="dark" control={<Radio />} label="Dark" />
      </RadioGroup>

      <Typography variant="h6" sx={{ my: 2 }}>
        Preferences
      </Typography>
      <AutoForm
        sx={{ pl: 2 }}
        schema={autoformConfigSchema}
        defaultValues={{ showContentColumns: config.showContentColumns }}
        onSubmit={handleUpdateConfig}
      />

      <Typography variant="caption">You can change the default transposition of songs here.</Typography>
      <Transpose value={config.musicPreferences?.transpose} onChange={handleTranspose} />

      {/* Configuration from the plugins below */}

      {configSpecs.map(({ id, config, schema, name }) =>
        schema ? (
          <Fragment key={id}>
            <Typography variant="h6" sx={{ my: 2 }}>
              {name ?? capitalize(id)}
            </Typography>
            <AutoForm
              sx={{ pl: 2 }}
              schema={schema}
              defaultValues={config}
              onSubmit={val => handleUpdatePluginConfig(id, schema, val)}
            />
          </Fragment>
        ) : null
      )}
    </Box>
  )
}

export default PluginConfig
