import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { Chord } from '@tunasong/music-lib'
import { ChordLib, ScaleLib, isChordVariant } from '@tunasong/music-lib'
import type { Scale } from '@tunasong/schemas'
import type { FC, MouseEvent } from 'react'
import { NoteDegree } from '../note/index.js'

export interface SimpleChordProps extends Omit<BoxProps, 'onClick'> {
  chord: Chord
  /** Transpose the display by a number of semitones ( + / - ) */
  transpose?: number
  /** Display transposition as part of the chord (+/-)transposition */
  showTranspose?: boolean
  scale?: Scale | null
  selected?: boolean
  focused?: boolean
  onClick?(chord: Chord): void
}

export const SimpleChord: FC<SimpleChordProps> = props => {
  const {
    chord,
    scale,
    showTranspose = false,
    transpose = 0,
    selected = false,
    focused = false,
    onClick,
    ...restProps
  } = props

  const chordName = ChordLib.getName(chord, transpose)
  const inScale = scale ? ScaleLib.inScale(scale, chord) : true
  /** We don't know that the root is in the custom chord, so we cannot calculate the degree */
  const degree = isChordVariant(chord) && scale && inScale && ScaleLib.getChordDegree(scale, chord)
  const handleClick = (ev: MouseEvent) => {
    ev.preventDefault()
    onClick?.(chord)
  }
  return (
    <Box
      sx={{
        position: 'relative',
        color: inScale ? 'primary.main' : 'warning.light',
        backgroundColor: selected && focused ? 'action.selected' : 'inherit',
        textDecoration: onClick ? 'underline' : 'none',
        ':hover': onClick ? { cursor: 'pointer' } : {},
      }}
      contentEditable={false}
      onClick={handleClick}
      {...restProps}
    >
      {chordName}
      {degree ? <NoteDegree degree={degree} /> : null}
      {showTranspose && transpose !== 0 ? ` (${transpose > 0 ? '+' : '-'}${transpose})` : null}
    </Box>
  )
}
