import { graphHooks } from '@tunasong/graph-lib/react'
import type { FilterFunc } from '@tunasong/models'
import { logger } from '@tunasong/models'
import type { Chat, Entity, Persisted } from '@tunasong/schemas'
import { useEffect, useRef } from 'react'

interface ChildChatProps {
  element: Persisted<Entity>
  /** If specified, create a new chat if the chat is missing using the provided template */
  createIfMissing?: Partial<Chat>
  /** We can have multiple child chats. Apply filter and expect 0 or 1 remaining chats. */
  filter?: FilterFunc
}

/** Get the child chat matching the filter */
export const useChildChat = ({ element, createIfMissing, filter }: ChildChatProps) => {
  const { createEntity } = graphHooks.useEntityCreate()

  const { child: chat, isLoading } = graphHooks.useEntityChild<Persisted<Chat>>({
    parentId: element?.id,
    filter: filter ?? 'chat',
  })

  /**
   * If child is null it doesn't exist. If so, create it if instructed to
   *
   * We are getting into trouble here due to the StateWhileRevalidate strategy in the Service Worker.
   * If we re-load the page, the chat will be missing (Stale cache) and we will create it again.
   */
  const isCreating = useRef(false)
  useEffect(() => {
    // chat === null means we have loaded the children and no chat is found.
    if (chat !== null || isCreating.current) {
      return
    }
    if (!createIfMissing) {
      return
    }

    const entity = {
      ...createIfMissing,
      type: 'chat',
      parentId: element.id,
    } satisfies Entity

    isCreating.current = true
    createEntity({ entity, parent: element })
      .then(() => {
        isCreating.current = false
      })
      .catch(error => {
        logger.error('Error creating chat', error)
        isCreating.current = false
      })
  }, [chat, createEntity, createIfMissing, element])

  return {
    chat,
    isLoading,
  }
}
