import { LinearProgress, MenuList, Typography } from '@mui/material'
import type { BoxProps } from '@mui/material'
import type { SearchSummary } from '@tunasong/models'
import { usePlugins } from '@tunasong/plugin-lib'
import type { Entity, Persisted } from '@tunasong/schemas'
import { VBox, VisibilityTrigger } from '@tunasong/ui-lib'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getAvatarOrIcon } from './avatar.js'
import { SearchResultListItem } from './search-result-item.js'

export interface SearchResultsProps<T extends SearchSummary | Persisted<Entity> = SearchSummary>
  extends Omit<BoxProps, 'onChange'> {
  entities: T[]
  similar?: T[]
  /** Is the search in progress? */
  searching?: boolean
  /** Default selected index */
  selectedIndex?: number
  /** Search input element */
  inputRef?: HTMLElement | null
  renderHotkeys?(entity: T, active: boolean): React.ReactElement | null
  onChange(entity: T): void
  onHighlightChange?(entity: T): void
  /** Triggered when the user has scrolled to the end of the result set */
  onEndOfResults?(): Promise<void>
}

export function SearchResults<T extends SearchSummary>(props: SearchResultsProps<T>) {
  const {
    searching,
    inputRef,
    renderHotkeys,
    onChange,
    onHighlightChange,
    onEndOfResults,
    entities,
    similar,
    selectedIndex,
    ...boxProps
  } = props
  const plugins = usePlugins('all')

  const handleChange = useCallback(
    (entity: T) => () => {
      if (onChange) {
        onChange(entity)
      }
    },
    [onChange]
  )

  const [forcedActive, setActive] = useState<number>(-1)
  const active = typeof selectedIndex === 'number' ? selectedIndex : forcedActive

  /** Active MenuItem */
  const activeRef = useRef<HTMLLIElement | null>(null)

  /** Search input */

  const handleKey = useCallback(
    (ev: KeyboardEvent | React.KeyboardEvent) => {
      if (ev.key === 'ArrowDown') {
        ev.preventDefault()
        setActive(active => (active < entities.length - 1 ? active + 1 : entities.length - 1))
      } else if (ev.key === 'ArrowUp') {
        ev.preventDefault()
        setActive(active => (active > 0 ? active - 1 : -1))
      } else if (ev.key === 'Enter') {
        ev.preventDefault()
        if (entities[active]) {
          onChange(entities[active])
        }
      }
    },
    [entities, active, onChange]
  )

  /** Register onKeyDown handlers */
  useEffect(() => {
    if (!inputRef) {
      return
    }
    inputRef.addEventListener('keydown', handleKey)
    return () => {
      inputRef.removeEventListener('keydown', handleKey)
    }
  }, [handleKey, inputRef])

  /** Scroll into view when active changes */
  useEffect(() => {
    const a = entities[active]
    if (activeRef.current) {
      activeRef.current.scrollIntoView({ block: 'center' })
    }
    if (onHighlightChange) {
      onHighlightChange(a)
    }
  }, [activeRef, active, entities, onHighlightChange])

  const getItem = (entity: T, idx: number) => (
    <SearchResultListItem
      key={entity.id}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      icon={getAvatarOrIcon(entity, plugins!)}
      ref={idx === active ? activeRef : null}
      selected={idx === active}
      element={entity}
      breadCrumbLinks={false}
      onClick={handleChange(entity)}
      actions={renderHotkeys ? renderHotkeys(entity, idx === active) : null}
    />
  )

  if (!plugins) {
    return null
  }

  const hasResults = entities.length + (similar?.length ?? 0) > 0

  return (
    <VBox sx={{ flex: 1, ...boxProps?.sx }} {...boxProps}>
      {!hasResults ? (
        searching ? (
          <LinearProgress />
        ) : (
          <Typography sx={{ p: 2, color: theme => theme.vars.palette.action.disabled }}>No results</Typography>
        )
      ) : null}

      {hasResults ? (
        <>
          <MenuList onKeyDown={handleKey}>
            {entities.length > 0 ? entities.map((entity, idx) => getItem(entity, idx)) : null}
            {similar &&
              similar.length > 0 && [
                <Typography key={'similar-index'} sx={{ p: 2, color: theme => theme.vars.palette.action.disabled }}>
                  Similar to current doc
                </Typography>,
                ...similar.map((entity, idx) => getItem(entity, entities.length + idx)),
              ]}
          </MenuList>
          <VisibilityTrigger onVisible={onEndOfResults} />
        </>
      ) : null}
    </VBox>
  )
}
