import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemSecondaryAction,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import type { BoxProps, SelectChangeEvent } from '@mui/material'
import { Check } from '@tunasong/icons'
import { ChordLib, GuitarLib, NOTE_NAMES, isChordVariant, prettyPrint } from '@tunasong/music-lib'
import type { Chord, ChordVariant } from '@tunasong/music-lib'
import { HBox } from '@tunasong/ui-lib'
import React, { useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
import { FindGuitarChord } from '../guitar/find-chord/index.js'
import { KeyboardChord } from '../keyboard/index.js'
import { Note } from '../note/index.js'
import { SimpleChord } from './simple-chord.js'
import type { NoteName, Scale } from '@tunasong/schemas'
import { GuitarChord } from '../guitar/index.js'

export interface ChordChooserProps extends Omit<BoxProps, 'onChange'> {
  chord?: Chord
  scale?: Scale | null

  onChange(props: { chord: Chord; defaultView: string }): void
}

export const ChordChooser: FC<ChordChooserProps> = props => {
  const { chord, scale, onChange, ...boxProps } = props
  const [root, setRoot] = useState('C')
  const [keyChords, setKeyChords] = useState<Chord[]>(chord ? [chord] : [])

  const [currentNotes, setCurrentNotes] = useState<NoteName[]>(chord ? ChordLib.getNotes(chord) : [])

  const handleFocus = useCallback(
    (focusChord: ChordVariant) => () => {
      const notes = ChordLib.getNotes(focusChord)
      setCurrentNotes(notes)
      if (!ChordLib.equals(focusChord, chord)) {
        onChange({
          chord: {
            ...focusChord,
            notes: undefined,
            fingering: undefined,
            name: undefined,
          } as Chord,
          defaultView: 'guitar',
        })
      }
    },
    [chord, onChange]
  )

  const handleGuitarChord = useCallback(
    (chord: GuitarLib.GuitarChord) => {
      /** @todo onChange is a partial update, so we need to clear root and variant.   */
      onChange({
        chord: {
          ...chord,
          root: undefined,
          variant: undefined,
        },
        defaultView: 'guitar',
      })
      setCurrentNotes(chord.notes)
    },
    [onChange]
  )

  useEffect(() => {
    if (isChordVariant(chord) && chord?.root) {
      setRoot(chord.root)
    }
  }, [chord])

  const handleSelect = useCallback(
    (c: Chord) => () => {
      onChange({ chord: c, defaultView: 'guitar' })
    },
    [onChange]
  )
  const handleKeyDown = useCallback(
    (ch: Chord) => (ev: React.KeyboardEvent) => {
      if (ev.key === 'Enter') {
        ev.preventDefault()
        onChange({ chord: ch, defaultView: 'guitar' })
      }
    },
    [onChange]
  )

  /** Focus on the active chord   */
  const [activeRef, setActiveRef] = useState<HTMLDivElement | null>(null)
  useEffect(() => {
    activeRef?.focus()
  }, [activeRef])

  const chords = ChordLib.getChordNames()
    .map(n => ChordLib.fromName(n))
    .filter(Boolean)
    .filter(c => c?.root === root)
    .map((ch, idx) => {
      const fingering = GuitarLib.getFingering(ch)
      const matchChord = isChordVariant(chord) ? chord : undefined
      return ch && fingering ? (
        <Grid key={idx}>
          {ChordLib.equals(ch, matchChord) ? (
            <Paper
              sx={{
                padding: 1,
                paddingBottom: 0,
                backgroundColor: 'action.hover',
                borderColor: 'primary.main',
                borderWidth: 2,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              ref={ChordLib.equals(ch, matchChord) ? setActiveRef : null}
              variant="outlined"
              tabIndex={0}
              onClick={handleSelect(ch)}
              onFocus={handleFocus(ch)}
              onKeyDown={handleKeyDown(ch)}
            >
              <GuitarChord chord={ch} scale={scale} />
            </Paper>
          ) : (
            <Paper
              sx={{
                padding: 1,
                paddingBottom: 0,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              ref={ChordLib.equals(ch, matchChord) ? setActiveRef : null}
              variant="outlined"
              tabIndex={0}
              onClick={handleSelect(ch)}
              onFocus={handleFocus(ch)}
              onKeyDown={handleKeyDown(ch)}
            >
              <GuitarChord chord={ch} scale={scale} />
            </Paper>
          )}
        </Grid>
      ) : null
    })

  const options = NOTE_NAMES.map(n => (
    <MenuItem key={n} value={n}>
      {prettyPrint(n)}
      {isChordVariant(chord) && n === chord?.root && (
        <ListItemSecondaryAction>
          <Check />
        </ListItemSecondaryAction>
      )}
    </MenuItem>
  ))

  const handleChangeRoot = (ev: SelectChangeEvent) => setRoot(ev.target.value)
  const initialTab = GuitarLib.isGuitarChord(chord) ? 1 : 0
  const [tab, setTab] = useState(initialTab)
  const handleTab = useCallback((ev: unknown, value: number) => {
    setTab(value)
    setCurrentNotes([])
  }, [])

  const handleKeyboardChords = (chords: Chord[]) => {
    setKeyChords(chords)
    if (chords.length === 0) {
      return
    }
    const chord = chords[0]
    const notes = ChordLib.getNotes(chord)
    setCurrentNotes(notes)
    onChange({ chord, defaultView: 'keyboard' })
  }

  return (
    <Box {...boxProps}>
      <Tabs sx={{ mb: 2 }} value={tab} onChange={handleTab}>
        <Tab label={`Guitar Library`} value={0} />
        <Tab label={`Guitar Fretboard`} value={1} />
        <Tab label={`Keyboard`} value={2} />
        {/* <Tab label={`Tunabrain`} value={2} /> */}
      </Tabs>
      {tab === 0 ? (
        <>
          <FormControl sx={{ minWidth: 100 }} variant="standard">
            <InputLabel htmlFor="root">Root Note</InputLabel>
            <Select value={root} onChange={handleChangeRoot}>
              {options}
            </Select>
          </FormControl>
          <Grid container={true} spacing={2}>
            {chords}
          </Grid>
        </>
      ) : tab === 1 ? (
        <FindGuitarChord
          sx={{ minWidth: 500 }}
          defaultChord={GuitarLib.isGuitarChord(chord) ? chord : undefined}
          scale={scale}
          onSelected={handleGuitarChord}
        />
      ) : tab === 2 ? (
        <>
          <KeyboardChord
            defaultChord={chord}
            scale={scale}
            showLabelOnActiveOnly={false}
            largeKeyboard={false}
            onChordChange={handleKeyboardChords}
          />
          <Divider sx={{ my: 2 }}>
            <Typography variant="caption">Identified Chords</Typography>
          </Divider>

          <HBox>
            {keyChords.map((c, idx) => (
              <SimpleChord sx={{ fontSize: '2em', mr: 2 }} key={idx} chord={c} />
            ))}
          </HBox>
        </>
      ) : tab === 3 ? (
        'Suggestions are coming soon!'
      ) : null}

      <Divider sx={{ my: 2 }}>
        <Typography variant="caption">Notes used</Typography>
      </Divider>
      <HBox sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        {currentNotes ? (
          <Box>
            {currentNotes.map((n, idx) => (
              <Note sx={{ m: 1 }} key={idx} note={n} scale={scale} />
            ))}
          </Box>
        ) : (
          <Typography variant="body2">N/A</Typography>
        )}
      </HBox>
    </Box>
  )
}

export default ChordChooser
