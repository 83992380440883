/** Content shared with you. The server-side will filter on the ACLs, so all entities will be yours to see */
import type { SearchRequest } from '@tunasong/schemas'
import type { QueryProps } from './query-props.js'
const query = ({ query: userId, size = 1000 }: QueryProps): SearchRequest =>
  ({
    index: 'entities',
    size,
    from: 0,
    _source_excludes: ['embedding', 'acls', 'metadata'],
    body: {
      sort: [{ updatedAt: { order: 'desc' } }, '_score'],
      query: {
        bool: {
          must_not: {
            bool: {
              filter: [
                {
                  term: {
                    // must use keyword here
                    'userId.keyword': userId,
                  },
                },
                /** Additional filters here */
              ],
            },
          },
          must: {
            terms: {
              /** @todo new space types must be added here */
              type: ['song', 'page', 'folder', 'bandspace'],
            },
          },
        },
      },
    },
  }) satisfies SearchRequest

export default query
