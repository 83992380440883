import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import type { AccordionProps } from '@mui/material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import type { TunaPluginRenderContextProps } from '@tunasong/plugin-lib'
import type { FC } from 'react'
import React, { useCallback, useState } from 'react'

export interface PanelProps extends Omit<AccordionProps, 'children'>, Partial<TunaPluginRenderContextProps> {
  className?: string
  title?: string
  subtitle?: string
  gutters?: boolean
  children?: React.ReactNode
}

const Panel: FC<PanelProps> = props => {
  const { className, gutters = true, title = 'Unknown', subtitle, defaultExpanded = true, children } = props
  const [expanded, setExpanded] = useState(defaultExpanded)
  const handleExpand = useCallback((ev: unknown, expanded: boolean) => setExpanded(expanded), [])

  return (
    <Accordion className={className} elevation={0} variant="outlined" expanded={expanded} onChange={handleExpand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          sx={{
            fontSize: theme => theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
            flex: 1,
          }}
        >
          {title}
        </Typography>
        {subtitle ? (
          <Typography
            sx={{
              fontSize: theme => theme.typography.pxToRem(15),
              color: theme => theme.vars.palette.text.secondary,
            }}
          >
            {subtitle}
          </Typography>
        ) : null}
      </AccordionSummary>

      <AccordionDetails
        sx={{
          padding: theme => (gutters ? theme.spacing(1, 2, 0, 2) : theme.spacing(1, 0, 0, 0)),
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default Panel
