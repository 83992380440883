import type { SearchRequest } from '@tunasong/schemas'
import type { QueryProps } from './query-props.js'

/** Related content. Query is assumed to be the name of an entity */
const query = ({ query, size = 25, filter }: QueryProps): SearchRequest =>
  ({
    index: 'entities',
    size,
    from: 0,
    /** embeddings are large - don't return them by default */
    _source_excludes: ['embedding', 'acls'],
    body: {
      sort: [{ updatedAt: { order: 'desc' } }, '_score'],
      query: {
        bool: {
          must: [
            {
              match_phrase: {
                name: {
                  query: `${query}`,
                  boost: 5,
                },
              },
            },
            {
              query_string: {
                query: `${query}*`,
                fields: ['name'],
              },
            },
          ],
          filter: [
            {
              bool: {
                must_not: [
                  // additional filters here
                  { term: { trash: true } },
                ],
                must: [
                  // additional filters here

                  filter?.types && filter.types.length > 0
                    ? {
                        terms: { type: filter.types },
                      }
                    : null,
                  filter?.tags && filter.tags.length > 0 ? { terms: { tags: filter.tags } } : null,
                ].filter(Boolean),
              },
            },
          ],
        },
      },
    },
  }) satisfies SearchRequest

export default query
