import { Box, Button, FormLabel, TextField, Typography } from '@mui/material'
import { getImageMimeTypes, usePlugins } from '@tunasong/plugin-lib'
import { features, OAuth, profilesApi, useSelector, useThunkDispatch } from '@tunasong/redux'
import type { Entity, Persisted, Profile } from '@tunasong/schemas'
import { DropZone, HBox, UserAvatar, VBox } from '@tunasong/ui-lib'
import React, { useCallback } from 'react'
import type { FC } from 'react'

export interface AccountProps {
  className?: string
  profile: Persisted<Profile>
}

export const Account: FC<AccountProps> = props => {
  const { profile } = props

  const dispatch = useThunkDispatch()
  const user = useSelector(state => state.user)

  const [updateProfile] = profilesApi.useUpdateProfileMutation()

  const logout = useCallback(() => {
    OAuth.logout()
    dispatch(features.user.actions.signout())
  }, [dispatch])

  const imageMimeTypes = getImageMimeTypes(usePlugins() ?? [])

  if (!profile) {
    return null
  }

  const aiTokens = profile.usage.aiTokens ? Math.round(profile.usage.aiTokens) : 0

  const storageMB = (profile.usage.storage ?? 0) / (1024 * 1024)
  const storageGB = storageMB / 1024
  const storageLabel = `${storageGB > 1 ? Math.round(storageGB) + ' GB' : Math.round(storageMB) + ' MB'}`

  const updateProfileName = (ev: React.FocusEvent<HTMLInputElement>) => {
    updateProfile({ id: profile.id, profile: { name: ev.target.value } })
  }
  const handleUpdatePicture = (picture: Persisted<Entity>) => {
    // Update the profile picture. This link will expire, but the server profile API will overwrite it.
    updateProfile({ id: profile.id, profile: { picture: picture.storageUrls?.url } })
  }

  const handleUploading = () => {
    // Update the profile picture. This link will expire, but the server profile API will overwrite it.
    updateProfile({ id: profile.id, profile: { picture: null } })
  }

  return (
    <VBox>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Profile
      </Typography>

      <HBox gap={2}>
        <DropZone
          sx={{ minHeight: 0 }}
          parentId={profile.id}
          accept={imageMimeTypes}
          onUploaded={handleUpdatePicture}
          onUploading={handleUploading}
          uploadOnClick
        >
          <UserAvatar sx={{ mr: 2 }} userId={user.userId} size="large" aria-label="user" />
        </DropZone>
        <TextField
          label="My name"
          defaultValue={profile.name}
          variant="standard"
          fullWidth
          onBlur={updateProfileName}
        />
      </HBox>
      <Box>
        <Button color="inherit" onClick={logout}>
          Sign out
        </Button>
      </Box>

      <Typography variant="h6">Account</Typography>

      <FormLabel component="legend">E-mail</FormLabel>
      <Typography>{user.email} </Typography>
      <Typography variant="caption">{user.userId}</Typography>

      <Typography variant="h6" sx={{ my: 1 }}>
        Identity Provider
      </Typography>
      <Typography> {user.authProvider}</Typography>

      <Typography variant="h6" sx={{ my: 1 }}>
        Storage
      </Typography>
      <Typography variant="body2">You uploaded {storageLabel} content.</Typography>

      <Typography variant="h6" sx={{ my: 1 }}>
        AI tokens
      </Typography>
      <Typography variant="body2">You have used {aiTokens} AI tokens.</Typography>
    </VBox>
  )
}

export default Account
