import type { AppBarProps } from '@mui/material'
import { AppBar, Box, Toolbar } from '@mui/material'
import { GlobalPlayerButton } from '@tunasong/audio-ui'
import { useSelector } from '@tunasong/redux'
import { BackButton } from '@tunasong/ui-lib'
import type { JSX } from 'react'

interface HeaderProps extends AppBarProps {
  hideOnScrollTarget?: Node
  breadcrumbs?: JSX.Element
  controls?: JSX.Element | (JSX.Element | null)[]
  /** Show status icons - connectivity, MIDI, etc.  */
  showStatus?: boolean
  /** Show Back button */
  showBackButton?: boolean
}

export const Header = (props: HeaderProps) => {
  const {
    showBackButton = true,
    color = 'transparent',
    ref,
    controls,
    breadcrumbs,
    showStatus = true,
    ...restProps
  } = props
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)

  /** Show spinner if loading songs */
  if (!isLoggedIn) {
    return null
  }

  return (
    <AppBar color={color} {...restProps} ref={ref}>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: theme => theme.vars.palette.layers,
          zIndex: 1,

          height: 48,
          gap: theme => theme.spacing(),
        }}
        variant="dense"
      >
        {showBackButton ? (
          <BackButton
            sx={{
              display: 'flex',
              alignItems: 'center',
              '& :hover': {
                cursor: 'pointer',
              },
              marginRight: theme => theme.spacing(),

              paddingLeft: 0,
              paddingRight: 0,
              minWidth: 32,
            }}
          />
        ) : null}
        <Box sx={{ flex: 1, display: { xs: 'none', sm: 'none', md: 'block' } }}>{breadcrumbs}</Box>

        <GlobalPlayerButton showStatus={showStatus} />

        {controls}
      </Toolbar>
    </AppBar>
  )
}
