import type { MenuItemProps } from '@mui/material'
import {
  Box,
  capitalize,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import type { SearchSummary } from '@tunasong/models'
import { dayjs, isTimeStamp } from '@tunasong/models'
import { useElementTypeMedia } from '@tunasong/plugin-lib'
import { isProfile } from '@tunasong/schemas'
import { getColorCSS, TunaBreadcrumbs } from '@tunasong/ui-lib'
import type { JSX, Ref } from 'react'
import React from 'react'

export interface SearchResultListItemProps extends MenuItemProps {
  className?: string
  icon?: JSX.Element
  element: SearchSummary
  /** Show details */
  showDetails?: boolean
  /** Make the breadcrumbs active links */
  breadCrumbLinks?: boolean
  actions?: React.ReactElement | null
  ref: Ref<HTMLLIElement>
}

export const SearchResultListItem = (props: SearchResultListItemProps) => {
  const { element, showDetails = true, ref, actions, icon, breadCrumbLinks = true, ...restProps } = props
  const { materialColor: color } = useElementTypeMedia(element.type)

  const details = element?.type ? (
    <Typography variant="caption">
      <TunaBreadcrumbs
        sx={{ color: theme => theme.vars.palette.action.disabled }}
        parentChain={[element, ...(element.parentChain ?? [])].reverse()}
        variant="caption"
        showHome={false}
        showLeaf={false}
        activeLinks={breadCrumbLinks}
      />
      <Box sx={{ display: 'inline', color: getColorCSS(color) }}>{capitalize(element.type)}</Box>.{' '}
      {isTimeStamp(element) ? `Updated ${dayjs(element.updatedAt).fromNow()}` : ''}
    </Typography>
  ) : null

  const name = isProfile(element) ? (element.name ?? element.email) : element.name
  return (
    <MenuItem {...restProps} ref={ref}>
      <ListItemIcon>{icon}</ListItemIcon>

      <ListItemText
        disableTypography
        sx={{
          overflow: 'hidden',
          paddingRight: 4,
        }}
        primary={
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 500,
            }}
          >
            {name}
          </Typography>
        }
        secondary={showDetails ? details : null}
      ></ListItemText>

      <ListItemSecondaryAction>{actions}</ListItemSecondaryAction>
    </MenuItem>
  )
}

export default SearchResultListItem
