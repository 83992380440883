import type { InputBaseProps } from '@mui/material'
import { CircularProgress, InputBase } from '@mui/material'
import { Search as SearchIcon } from '@tunasong/icons'
import type { SearchFilter as SearchFilterType } from '@tunasong/schemas'
import { HBox, TagList, disableAutocomplete } from '@tunasong/ui-lib'
import type { ChangeEvent, FC } from 'react'

export interface SearchInputProps extends InputBaseProps {
  className?: string
  query: string
  loading: boolean
  placeholder: string
  fullWidth?: boolean
  filter?: SearchFilterType
  onChange(ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
}

export const SearchInput: FC<SearchInputProps> = props => {
  const { fullWidth, placeholder, query, loading, filter, onChange, ...restProps } = props
  const tags = [filter?.tags ?? [], filter?.types?.map(type => `type:${type}`)].filter(Boolean).flat()

  return (
    <InputBase
      {...restProps}
      fullWidth={fullWidth}
      value={query}
      startAdornment={
        <div
          style={{
            width: 4 * 8,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SearchIcon />
        </div>
      }
      endAdornment={
        <>
          {loading ? (
            <HBox sx={{ alignItems: 'center', mr: 1 }}>
              <CircularProgress color="inherit" size={20} />
            </HBox>
          ) : null}
          <TagList tags={tags} />
        </>
      }
      onChange={onChange}
      placeholder={placeholder}
      inputProps={{ ...disableAutocomplete }}
      sx={theme => ({
        color: 'inherit',
        backgroundColor: theme.vars.palette.action.focus,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(1),
        '& .MuiInputBase-input': {
          padding: theme.spacing(1, 1, 1, 4),
          transition: theme.transitions.create('width'),
          width: '100%',
        },
      })}
    />
  )
}

export default SearchInput
