import { Grid } from '@mui/material'
import { AutoForm } from '@tunasong/autoform'
import { graphHooks } from '@tunasong/graph-lib/react'
import type { SearchSummary } from '@tunasong/models'
import type { Command } from '@tunasong/plugin-lib'
import type { Entity, EntityCommandSpec, Persisted } from '@tunasong/schemas'
import { SearchList, allQueries } from '@tunasong/search'
import { EventCommandSelect, PulseProgress, useEventCommandEntityTypes } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import invariant from 'tiny-invariant'

export interface EventSelectProps {
  targetEntity?: Persisted<Entity>
  onSelect: (command: EntityCommandSpec | null) => void
}

/** Select an event command for an entity */
export const EntityEventSelect: FC<EventSelectProps> = props => {
  const { targetEntity, onSelect } = props
  const [summary, setSummary] = useState<SearchSummary>()

  const { entity: commandEntity, isLoading } = graphHooks.useEntity(summary?.id)

  const [selected, setSelected] = useState<Command | null>(null)

  const handleSelect = useCallback(
    (command: Command | null) => {
      if (!commandEntity) {
        return
      }

      setSelected(command)

      const isValid = command && !command.cmdParamsSchema

      if (isValid) {
        onSelect({
          entityId: commandEntity.id,
          commandId: command.id,
          label: command.name,
        })
      } else {
        onSelect(null)
      }
    },
    [commandEntity, onSelect]
  )

  const handleCommandParams = useCallback(
    (data: Record<string, unknown>) => {
      invariant(selected)
      invariant(commandEntity?.id)

      onSelect({
        entityId: commandEntity.id,
        commandId: selected.id,
        label: selected.name,
        commandParams: data,
      })
    },
    [commandEntity?.id, onSelect, selected]
  )
  const entityTypes = useEventCommandEntityTypes()
  const query = allQueries.type({ types: entityTypes })

  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 4 }}>
        <SearchList query={query} onChange={setSummary} selected={summary} />
      </Grid>
      <Grid size={{ xs: 12, sm: 8 }}>
        {isLoading ? <PulseProgress /> : null}
        <EventCommandSelect
          sx={{ ml: 1 }}
          entity={commandEntity}
          targetEntity={targetEntity}
          onClick={handleSelect}
          selected={selected}
          showOnlySelected={true}
        />
        {selected?.cmdParamsSchema ? (
          <AutoForm schema={selected.cmdParamsSchema} onSubmit={handleCommandParams} />
        ) : null}
      </Grid>
    </Grid>
  )
}

export default EntityEventSelect
