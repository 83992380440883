import { Box, Divider, Typography } from '@mui/material'
import { capitalize, dayjs } from '@tunasong/models'
import { getPlugin, usePlugins } from '@tunasong/plugin-lib'
import { ElementForm } from '@tunasong/ui-lib'
import type { SidebarComponent } from '@tunasong/ui-lib'
import type { FC } from 'react'

export const SidebarSettings: FC<SidebarComponent> = ({ entity, onChange }) => {
  const plugins = usePlugins('all')
  const EntityProperties = entity ? getPlugin(entity, plugins)?.components?.Properties : null

  return (
    <Box sx={{ overflow: 'auto' }}>
      <ElementForm element={entity} onChange={onChange} />
      {EntityProperties ? (
        <>
          <Divider sx={{ my: 1 }}>
            <Typography variant="caption">{capitalize(entity.type)} properties</Typography>
          </Divider>
          <EntityProperties element={entity} onChange={onChange} />
        </>
      ) : null}
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
        <Typography variant="caption">Created: {dayjs(entity.createdAt).format('L LT')}</Typography>
        <Typography variant="caption">Last updated: {dayjs(entity.updatedAt).format('L LT')}</Typography>
      </Box>
    </Box>
  )
}
