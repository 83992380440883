import type { SupportedColorScheme, Theme } from '@mui/material'
import invariant from 'tiny-invariant'

export const getGuitarColors = ({ theme, colorScheme }: { theme: Theme; colorScheme?: SupportedColorScheme }) => {
  const colors = theme.colorSchemes[colorScheme ?? 'light']
  invariant(colors, 'Color scheme ${colorScheme} not found')

  return {
    /** Constants */
    labelStyle: colors.palette.text.primary,
    muteStyle: colors.palette.error.main,
    outlineStyle: colors.palette.divider,
    watermarkStyle: colors.palette.text.secondary,
    nutStyle: colors.palette.action.disabled,
    fretStyle: colors.palette.text.primary,
    inlayStyle: colors.palette.text.primary,
    stringStyle: colors.palette.text.secondary,
    fingeringStyle: theme.notes?.main,
    fingeringStyleInScale: theme.notes?.inScale,
    fingeringStyleOutOfScale: theme.notes?.outOfScale,
  }
}
