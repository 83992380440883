import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { MetronomeButton } from '@tunasong/audio-ui'
import type { Rhythm } from '@tunasong/models'
import { meterToString } from '@tunasong/models'
import type { FC } from 'react'

export interface RhythmProps {
  className?: string
  rhythm?: Rhythm
}

const RhythmContainer = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

const RhythmItem = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}))

const RhythmComponent: FC<RhythmProps> = props => {
  const { className, rhythm } = props
  if (!rhythm) {
    return null
  }

  return (
    <RhythmContainer className={className}>
      <RhythmItem>
        <MetronomeButton rhythm={rhythm} />
      </RhythmItem>
      <RhythmItem>
        <Typography variant="body2" color="inherit">
          {rhythm.tempo} BPM
        </Typography>
      </RhythmItem>
      {rhythm.meter && (
        <RhythmItem>
          <Typography variant="body2" color="inherit">
            {meterToString(rhythm.meter)}
          </Typography>
        </RhythmItem>
      )}
    </RhythmContainer>
  )
}

export default RhythmComponent
