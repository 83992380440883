import type { PaperProps } from '@mui/material'
import { Box } from '@mui/material'
import type { FilterFunc, SearchSummary } from '@tunasong/models'
import { usePlugins } from '@tunasong/plugin-lib'
import type { Entity, Persisted, SearchFilter } from '@tunasong/schemas'
import React, { useCallback, useState } from 'react'
import { SearchResults } from './search-results.js'
import SearchInput from './search-input.js'

export interface SearchBarProps<T extends SearchSummary | Persisted<Entity>> extends Pick<PaperProps, 'sx'> {
  className?: string
  placeholder?: string
  loading?: boolean
  entities: T[]
  similar?: T[]
  fullWidth?: boolean
  selected?: T
  query: string

  /** Filter in query. These are AND'ed  */
  filter?: SearchFilter

  /** @todo this filters after the result is returned */
  resultFilter?: FilterFunc

  renderHotkeys?(entity: T, active: boolean): React.ReactElement | null
  /** Query has changed */
  onQuery(query: string): void
  onChange(entity: T): void
  onKeyDown?(ev: React.KeyboardEvent): void
  onKeyUp?(ev: React.KeyboardEvent): void
}

export function SearchBar<T extends SearchSummary>(props: SearchBarProps<T>) {
  const {
    query,
    loading = false,
    placeholder = 'Search...',
    fullWidth = true,
    entities,
    similar,
    selected,
    filter,
    renderHotkeys,
    onChange,
    onQuery,
    ...boxProps
  } = props
  // Using searchClasses for SearchInput component compatibility
  const plugins = usePlugins('all')

  const handleQuery = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const q = ev.target.value

      onQuery(q)
    },
    [onQuery]
  )

  const [inputRef, setInputRef] = useState<HTMLElement | null>(null)

  if (!plugins) {
    return null
  }

  const selectedIndex = selected ? entities.findIndex(e => e.id === selected.id) : undefined

  return (
    <Box
      {...boxProps}
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
        ...boxProps?.sx,
      }}
    >
      <SearchInput
        inputRef={setInputRef}
        onChange={handleQuery}
        query={query}
        placeholder={placeholder}
        filter={filter}
        loading={loading}
        autoFocus={true}
        fullWidth={fullWidth}
        {...boxProps}
      />

      <SearchResults
        sx={{ overflow: 'auto' }}
        inputRef={inputRef}
        entities={entities}
        selectedIndex={selectedIndex}
        similar={similar}
        renderHotkeys={renderHotkeys}
        onChange={onChange}
      />
    </Box>
  )
}
