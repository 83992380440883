import type { MenuItemProps } from '@mui/material'
import { DialogContent, ListItemIcon, MenuItem, Typography } from '@mui/material'
import { Help as HelpIcon } from '@tunasong/icons'
import { Dialog, ShortcutBox, shortcuts } from '@tunasong/ui-lib'
import type { FC, MouseEvent } from 'react'
import { useState } from 'react'

export interface HelpMenuItemProps extends Omit<MenuItemProps, 'button'> {
  className?: string
  onClose?: () => void
}

export const HelpMenuItem: FC<HelpMenuItemProps> = props => {
  const { className, onClose, onClick, ...restProps } = props
  const [showHelp, setShowHelp] = useState(false)

  const handleShowHelp = (ev: MouseEvent<HTMLLIElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (onClick) {
      onClick(ev)
    }
    setShowHelp(true)
  }
  const handleHideHelp = () => {
    setShowHelp(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <MenuItem className={className} title="Show help" color="inherit" onClick={handleShowHelp} {...restProps}>
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <Typography variant="inherit">Help</Typography>
      </MenuItem>
      <Dialog open={showHelp} onClose={handleHideHelp} title="Keyboard Shortcuts">
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {Object.values(shortcuts).map((s, idx) => (
            <ShortcutBox key={idx} shortcut={s} />
          ))}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default HelpMenuItem
