/** Parse the prefixes in the query */
import { type SearchFilter } from '@tunasong/schemas'

// Define allowed prefixes and their ElasticSearch mappings
const ALLOWED_PREFIXES = {
  tag: {
    type: 'term',
    field: 'tags.keyword',
  },
  type: {
    type: 'term',
    field: 'tags.keyword',
  },
  before: {
    type: 'range',
    field: 'created_at',
    rangeType: 'lte',
  },
  after: {
    type: 'range',
    field: 'created_at',
    rangeType: 'gte',
  },
}

// Parse the search query into segments (prefixes and plain text)
export const parseQuerySegments = (query: string) => {
  let cleanQuery = query
  const filter: SearchFilter = {
    tags: [],
    types: [],
  }

  const words = query.split(/(\s+)/)
  for (const word of words) {
    const prefixMatch = word.match(/^(\w+):(.+)$/)
    if (prefixMatch) {
      const [fullMatch, prefix, value] = prefixMatch
      // Validate prefix
      const isValid = prefix in ALLOWED_PREFIXES && value
      if (!isValid) {
        continue
      }

      // Remove the prefix from the clean query
      cleanQuery = cleanQuery.replace(fullMatch, '')

      if (prefix === 'tag') {
        filter.tags?.push(value)
      }
      if (prefix === 'type') {
        filter.types?.push(value)
      }
    }
  }

  return { filter, cleanQuery: cleanQuery.trim() }
}

// Generate ElasticSearch query from segments
// const generateElasticQuery = (segments) => {
//   const must = [];
//   const filter = [];

//   segments.forEach(segment => {
//     if (segment.type === 'prefix' && segment.isValid) {
//       const config = ALLOWED_PREFIXES[segment.prefix];

//       switch (config.type) {
//         case 'term':
//           filter.push({
//             term: {
//               [config.field]: segment.value
//             }
//           });
//           break;
//         case 'match':
//           must.push({
//             match: {
//               [config.field]: segment.value
//             }
//           });
//           break;
//         case 'range':
//           filter.push({
//             range: {
//               [config.field]: {
//                 [config.rangeType]: segment.value
//               }
//             }
//           });
//           break;
//       }
//     } else if (segment.type === 'text' && segment.text.trim()) {
//       must.push({
//         multi_match: {
//           query: segment.text.trim(),
//           fields: ['title^2', 'content']
//         }
//       });
//     }
//   });

//   return {
//     bool: {
//       must: must.length ? must : [{ match_all: {} }],
//       filter
//     }
//   };
// }
