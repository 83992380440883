import Close from '@mui/icons-material/Close'
import { Box, IconButton } from '@mui/material'
import type { Instrument } from '@tunasong/schemas'
import { ButtonLink, Dialog } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import InstrumentEditor from './instrument-editor.js'

export interface InstrumentViewProps {
  className?: string
  instrument: Instrument
  showEffects?: boolean
  showDetails?: boolean
  onChange?(instrument: Instrument): void
  onRemove?(): void
}

const InstrumentView: FC<InstrumentViewProps> = props => {
  const { className, instrument, showDetails: showDetailsOnMount = false, onChange, onRemove } = props
  const [showDetails, setShowDetails] = useState(showDetailsOnMount)

  const handleDetails = useCallback(() => {
    setShowDetails(true)
  }, [])

  const handleCloseDetails = useCallback(() => {
    setShowDetails(false)
  }, [])

  const label = instrument.name ? instrument.name : instrument.type

  return (
    <>
      <Box display="flex" flexDirection="column" className={className}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box
            sx={{
              flexGrow: 1,
              '&:hover': {
                textDecoration: 'underline',
                color: theme => theme.vars.palette.grey[500],
              },
            }}
          >
            <ButtonLink onClick={handleDetails}>{label}</ButtonLink>
          </Box>

          {onRemove && (
            <IconButton
              sx={{
                '& svg': {
                  fontSize: 16,
                },
                color: theme => theme.vars.palette.grey[500],
              }}
              onClick={onRemove}
            >
              <Close />
            </IconButton>
          )}
        </Box>
      </Box>
      <Dialog open={showDetails} title={`${instrument.type} details`} onClose={handleCloseDetails}>
        <InstrumentEditor instrument={instrument} onChange={onChange} />
      </Dialog>
    </>
  )
}

export default InstrumentView
