import { Box, IconButton } from '@mui/material'
import { green } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import { DeviceSelect, useMixer } from '@tunasong/audio-ui'
import type { MicControl } from '@tunasong/audio-ui'
import { Dialog, useRedraw } from '@tunasong/ui-lib'
import { Headset, MicOff, Microphone, Present, PresentOff, Settings, VideoCam, VideoCamOff } from '@tunasong/icons'
import { useCallback, useState } from 'react'
import type { FC } from 'react'
import type { StreamControl } from '../hooks/index.js'

const canShare = () => true

export interface RoomControlsProps {
  entityId: string

  disabled?: boolean

  /** Number of media items available */
  mediaCount?: number

  micControl: MicControl
  streamControl: StreamControl

  onMedia(open: boolean): void
}

const ControlsContainer = styled('div')({})

interface StyledIconProps {
  isOff?: boolean
  isOn?: boolean
}

const StyledIcon = styled('span', {
  shouldForwardProp: prop => prop !== 'isOff' && prop !== 'isOn',
})<StyledIconProps>(({ theme, isOff, isOn }) => ({
  height: '1.3em',
  width: '1.3em',
  ...(isOff && {
    color: theme.vars.palette.error.main,
  }),
  ...(isOn && {
    color: green[500],
  }),
}))

export const RoomControls: FC<RoomControlsProps> = props => {
  const { micControl, disabled, streamControl } = props
  const [inputSelectOpen, setInputSelectOpen] = useState(false)
  const mixer = useMixer()
  const redraw = useRedraw()

  const { mute: muteVideo, isSharing, isMuted: isMutedVideo, start: selectVideo } = streamControl
  const { headset, headsetMode, mute: muteMic, isMuted: isMutedMic } = micControl

  const handleHeadset = useCallback(() => headset(!headsetMode), [headset, headsetMode])

  const handleMuteVideo = useCallback(() => muteVideo(!isMutedVideo), [isMutedVideo, muteVideo])
  const handleMuteAudio = useCallback(() => {
    if (!mixer) {
      return
    }

    muteMic(!isMutedMic)
    redraw()
  }, [isMutedMic, mixer, muteMic, redraw])

  const toggleShareScreen = useCallback(() => {
    if (isSharing) {
      selectVideo('webcam')
    } else {
      selectVideo('screenshare')
    }
  }, [isSharing, selectVideo])

  const handleAudioInput = useCallback(
    (device: MediaDeviceInfo) => {
      micControl.setDevice(device)
    },
    [micControl]
  )
  const handleVideoInput = useCallback(
    (device: MediaDeviceInfo) => {
      streamControl.setDevice(device)
    },
    [streamControl]
  )

  const openInputSelect = useCallback(() => setInputSelectOpen(true), [])
  const closeInputSelect = useCallback(() => setInputSelectOpen(false), [])

  return (
    <ControlsContainer>
      <IconButton disabled={disabled} title="Mute audio" onClick={handleMuteAudio}>
        {isMutedMic ? <StyledIcon as={MicOff} isOff /> : <StyledIcon as={Microphone} />}
      </IconButton>
      <IconButton disabled={disabled} title="Mute video" onClick={handleMuteVideo}>
        {isMutedVideo ? <StyledIcon as={VideoCamOff} isOff /> : <StyledIcon as={VideoCam} />}
      </IconButton>
      <IconButton disabled={disabled} title="Low Latency (use headset)" onClick={handleHeadset}>
        {headsetMode ? <StyledIcon as={Headset} isOn /> : <StyledIcon as={Headset} />}
      </IconButton>

      <IconButton disabled={disabled || !canShare()} title="Share screen" onClick={toggleShareScreen}>
        {isSharing ? <StyledIcon as={PresentOff} isOff /> : <StyledIcon as={Present} />}
      </IconButton>

      {/* {rhythm ? <MetronomeButton title="Metronome" metronome={metronome} rhythm={rhythm} /> : null} */}

      <IconButton title="Show input settings" onClick={openInputSelect}>
        <StyledIcon as={Settings} />
      </IconButton>

      <Dialog open={inputSelectOpen} title="Select input source" onClose={closeInputSelect} showClose={true}>
        <DeviceSelect type="audioinput" selectedId={micControl.selectedDevice?.deviceId} onChange={handleAudioInput} />
        <Box mt={2} />
        <DeviceSelect
          type="videoinput"
          selectedId={streamControl.selectedDevice?.deviceId}
          onChange={handleVideoInput}
        />
      </Dialog>
    </ControlsContainer>
  )
}

export default RoomControls
