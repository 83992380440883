import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { getLink, hasPublicAccess, isEmail } from '@tunasong/models'
import { profilesApi } from '@tunasong/redux'
import type { ACL, Entity, ItemOperation, Persisted, Profile } from '@tunasong/schemas'
import { isProfile } from '@tunasong/schemas'
import {
  ConfirmDialog,
  CopyToClipboard,
  Dialog,
  ShareList,
  Slug,
  TransferEntity,
  getIFrameUrl,
  useCurrentUser,
} from '@tunasong/ui-lib'
import type { ChangeEvent, FC, KeyboardEvent } from 'react'
import React, { useCallback, useEffect, useState } from 'react'

export interface ShareInfoProps {
  className?: string
  open: boolean
  entity: Persisted<Entity>
  title?: string
  isLoading?: boolean
  onShare(acls: ACL[]): void
  onInvite(email: string, allow: ItemOperation): void
  onClose(): void
}

export const ShareDialog: FC<ShareInfoProps> = props => {
  const { className, entity: item, onShare, onInvite, onClose, isLoading, open, title = 'Share' } = props
  const [email, setEmail] = useState('')
  const { profiles } = graphHooks.useProfiles()
  const { userId } = useCurrentUser()

  const validEmail = isEmail(email)
  const [showInvite, setShowInvite] = useState(false)
  const [pending, setPending] = useState<string[]>([])

  const handleInvite = () => {
    setEmail('')
    setShowInvite(false)
    if (onInvite) {
      onInvite(email, 'WRITE')
      setPending(pending => [...pending, email])
    }
  }
  const handleDeclineInvite = () => {
    setEmail('')
    setShowInvite(false)
  }

  const [loadProfile, { isLoading: isLoadingProfile }] = profilesApi.useLazyLoadProfileQuery()
  const addShare = async () => {
    /** Check if the user/profile exists */
    let profile: Profile | undefined = Object.values(profiles ?? []).filter(p => p.email === email)[0]
    if (!isProfile(profile)) {
      profile = await loadProfile({ emailOrUserId: email }, true)
        .unwrap()
        .catch(() => undefined)
    }

    if (!profile) {
      setShowInvite(true)
      return
    }

    const principal = profile.userId

    /** Push the ACL onto the ACL list */
    onShare([
      ...(item.acls || []),
      {
        principal,
        permission: 'WRITE',
      },
    ])

    /** Clear input */
    setEmail('')
  }

  const handleClose = async () => {
    if (onClose) {
      onClose()
    }
  }

  const handleReturn = (ev: KeyboardEvent) => {
    if (ev.key !== 'Enter' || !validEmail) {
      return
    }
    addShare()
  }
  const handleDelete = (acl: ACL) => {
    const updatedAcls = [...(item.acls ?? [])]
    updatedAcls.splice(updatedAcls.indexOf(acl), 1)
    onShare(updatedAcls)
  }

  const handleEmail = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEmail(ev.target.value)
  }

  const handlePublicAccess = (ev: ChangeEvent<HTMLInputElement>) => {
    if (!ev.target.checked) {
      const acl = item.acls?.find(acl => acl.principal === 'PUBLIC')
      if (!acl) {
        throw new Error(`Cannot find public ACL!`)
      }
      return handleDelete(acl)
    }
    onShare([
      ...(item.acls || []),
      {
        principal: 'PUBLIC',
        permission: 'READ',
      },
    ])
  }

  /** Share tab */
  const [shareTab, setShareTab] = useState(0)
  const handleShareTab = useCallback((ev: unknown, val: number) => setShareTab(val), [])

  const isOwner = item.userId === userId
  /** We can't show the share tab when we're not the owner */
  useEffect(() => {
    if (!isOwner && shareTab === 3) {
      setShareTab(0)
    }
  }, [isOwner, shareTab])

  const entityUrl = URL.createObjectURL(new Blob([JSON.stringify(item)], { type: 'application/json' }))

  if (!item) {
    return null
  }

  const inviteText = `No user found for ${email}. Do you want to invite ${email} to Tunasong? The ${item.type} will be available to them when they sign up.`
  const url = getLink(item)

  const isPublic = hasPublicAccess(item.acls)
  const iFrameCode = getIFrameUrl(item)
  const handleTransferred = () => {
    setShareTab(0)
  }

  const isBusy = isLoading || isLoadingProfile

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        title={title}
        showClose={false}
        fullWidth={true}
        maxWidth={'sm'}
        disablePortal={false}
        onClick={ev => ev.stopPropagation()}
        className={className}
      >
        <DialogContent>
          {/* Share tools */}
          <Tabs sx={{ marginBottom: theme => theme.spacing(2) }} value={shareTab} onChange={handleShareTab}>
            <Tab label={`Users`} value={0} />
            <Tab label={`Link`} value={1} />
            <Tab label={`Embed`} value={2} />
            <Tab label={`Export`} value={3} />
            {isOwner ? <Tab label={`Transfer Ownership`} value={4} /> : null}
          </Tabs>
          {isBusy ? <LinearProgress /> : null}
          {shareTab === 0 && (
            <>
              <Box flex={1} display="flex">
                <TextField
                  disabled={isBusy}
                  sx={{ flex: 1 }}
                  key={item.id}
                  placeholder="E-mail to share with"
                  autoFocus={true}
                  variant="outlined"
                  onKeyDown={handleReturn}
                  onChange={handleEmail}
                />

                <Button disabled={!validEmail || isBusy} onClick={addShare}>
                  Add
                </Button>
              </Box>
              <ShareList entity={item} onDelete={handleDelete} pending={pending} />
            </>
          )}
          {shareTab === 1 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                color: theme => theme.vars.palette.text.secondary,
                alignItems: 'center',
              }}
            >
              <Typography variant="body2">{url}</Typography>
              <CopyToClipboard variant="icon" text={url} title="Copy URL" />
            </Box>
          )}

          {shareTab === 2 && (
            <Box display="flex" flexDirection="column">
              <FormControlLabel
                control={<Checkbox checked={isPublic} onChange={handlePublicAccess} name="public-access" />}
                label="Enable public read access"
              />
              {isPublic && (
                <>
                  <Typography variant="h5" gutterBottom>
                    Slug
                  </Typography>
                  <Slug entityId={item.id} />

                  <Typography variant="h5" gutterBottom>
                    Embed IFrame
                  </Typography>
                  <Box display="flex" flexDirection="row">
                    <code>{iFrameCode}</code>
                    <CopyToClipboard variant="icon" text={iFrameCode} title="Copy Embed code" />
                  </Box>
                </>
              )}
            </Box>
          )}
          {shareTab === 3 ? (
            <>
              <Button
                href={entityUrl}
                target="_blank"
                disabled={!entityUrl}
                download={item.name ? `${item.name}.json` : `${item.id}.json`}
                component={'a'}
                fullWidth
              >
                Export entity as JSON
              </Button>
            </>
          ) : null}
          {shareTab === 4 ? <TransferEntity entity={item} onTransferred={handleTransferred} /> : null}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        open={showInvite}
        onConfirm={handleInvite}
        onCancel={handleDeclineInvite}
        text={inviteText}
      ></ConfirmDialog>
    </>
  )
}

export default ShareDialog
