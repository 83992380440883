import { createTheme, styled } from '@mui/material'

export const roomTheme = createTheme({
  typography: {
    fontSize: 12,
  },
})

export const RoomContainer = styled('div')({
  height: '100%',
  display: 'flex',
})

export const VideoGrid = styled('div')(({ theme }) => ({
  height: '50%',
  flex: 2,
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
}))

export const GridItem = styled('div')({
  display: 'flex',
  flex: 1,
  height: '100%',
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
})

export const EntitiesContainer = styled('div')({
  overflowY: 'auto',
})

export const StreamContainer = styled('div')(({ theme }) => ({
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.5),
}))

export const TextElement = styled('div')(({ theme }) => ({
  top: theme.spacing(1),
  paddingBottom: theme.spacing(2),
}))

export const ContentContainer = styled('div')({
  display: 'flex',
  flex: 1,
  height: '100%',
  alignItems: 'center',
  overflow: 'hidden',
})

export const Sidebar = styled('div')({
  maxWidth: 300,
  position: 'relative',
})

export const ControlsOverlay = styled('div')({
  background: 'transparent',
  opacity: 0.9,
  width: 300,
  position: 'fixed',
  right: 0,
  bottom: 40,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

export const PlayIcon = styled('div')({
  width: '4em',
  height: '4em',
})

export const PlayButton = styled('button')({
  // No specific styles
})
