import { Tab, Tabs } from '@mui/material'
import { MidiSettings } from '@tunasong/audio-ui'
import { graphHooks } from '@tunasong/graph-lib/react'
import { usePlugins } from '@tunasong/plugin-lib'
import { useIsBusy, useSearchParams, useUserConfig, VBox } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { ReleaseNotesList } from '../releases/release-notes-list.js'
import { Trash } from '../trash.js'
import { Account } from './account.js'
import AIConfig from './ai-config.js'
import { PluginConfig } from './config.js'
import EventConfig from './event-config.js'
import Exports from './exports.js'
import CommandHotkeys from './hotkeys.js'

export const Profile: FC = () => {
  const plugins = usePlugins('all')
  const { profile } = graphHooks.useProfile()

  const [searchParams, setSearchParams] = useSearchParams()

  const tab = searchParams.get('tab') || 'account'

  const handleTab = (ev: unknown, tab: string) => {
    setSearchParams(
      { tab },
      {
        replace: true,
      }
    )
  }
  const [hotkeys, handleHotkeys] = useUserConfig('hotkeys')

  useIsBusy(!(profile && plugins))

  if (!(profile && plugins)) {
    return null
  }

  return (
    <>
      <Tabs value={tab} onChange={handleTab} scrollButtons={true} variant="scrollable">
        <Tab label={`Account`} value="account" />
        <Tab label={`Settings`} value="settings" />
        <Tab label={`AI`} value="ai" />
        <Tab label={`Keyboard Shortcuts`} value="shortcuts" />
        <Tab label={`MIDI`} value="midi" />
        <Tab label={`Events`} value="events" />
        {/* <Tab label={`Graph`} value="graph" /> */}
        <Tab label={`Exports`} value="exports" />
        <Tab label={`Releases`} value="releases" />
        <Tab label={`Trash`} value="trash" />
      </Tabs>
      <VBox sx={{ mx: 4, my: 2, flex: 1, overflow: 'auto' }}>
        {tab === 'account' ? <Account profile={profile} /> : null}
        {tab === 'settings' ? <PluginConfig profile={profile} plugins={plugins} /> : null}
        {tab === 'ai' ? <AIConfig /> : null}
        {tab === 'shortcuts' ? <CommandHotkeys hotkeys={hotkeys} onChange={handleHotkeys} /> : null}
        {tab === 'midi' ? <MidiSettings /> : null}
        {tab === 'events' ? <EventConfig /> : null}
        {/* {tab === 'graph' ? <Graph /> : null} */}
        {tab === 'exports' ? <Exports /> : null}
        {tab === 'trash' ? <Trash /> : null}
        {tab === 'releases' ? <ReleaseNotesList /> : null}
      </VBox>
    </>
  )
}

export default Profile
